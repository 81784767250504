import React, { useMemo } from "react";
import "./index.css";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

export default function BannerEmpreendimento({ dados, altura }) {
  const DivImagem = styled.div`
    background-image: url('${dados[0]?.empreendimento_imagem}');
    background-size: cover;
    background-position: center;
    display: flex;
    width: 105%;
    height: ${altura || '350px'};
    margin-left: -30px;
    margin-bottom: 40px;
    margin-top: -15px;
    ${(dados[0]?.empreendimento_imagem_mobile && `@media (max-width: 767px) {
      background-image: url('${dados[0]?.empreendimento_imagem_mobile}')!important;
      background-repeat: no-repeat!important;
      background-size: contain;
    }`) || ''}
  `;

  const bannerMobile = (
    <div className="banner-mobile">
      <img src={dados[0]?.empreendimento_imagem_mobile} alt="banner_empreendimento"/>
    </div>
  )
  return (dados[0]?.empreendimento_imagem && (isMobile ? bannerMobile :  <DivImagem/>)) || <></>
}
