import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../MenuTopo/index.css";
import DommusOnlineFlag from "../DommusOnlineFlag";
import Agenda from "./Agenda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPeopleArrows,
  faSignOutAlt,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, Form, Container } from "react-bootstrap";
import { AiOutlineFieldNumber } from "react-icons/ai";
import "../MenuLateral/index";
import guService from "../../services/GuService";
import processoService from "../../services/ProcessoService";
import { Icon } from "@iconify/react";
import calendarOutlineBadged from "@iconify-icons/clarity/calendar-outline-badged";
import ModalDommus from "../Modal";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { mascaraCpfCnpj } from "../../components/InputTextMask";
import { erroSwal } from "../../helpers/DommusSwal";
import userCicle from "@iconify-icons/fa-regular/user-circle";
import { isDesktop } from "react-device-detect";
import { ProcessoContext } from "../../contexts/ProcessoContext";
import formatDommus from "../../helpers/format";
import empreendimentoService from "../../services/EmpreendimentoService";
import configuracaoService from "../../services/ConfiguracaoService";
import { ConfiguracoesVisuaisContextContext } from "../../contexts/ConfiguracoesVisuaisContext";
import { LogNavegacaoService } from "../../services/LogNavegacaoService";

export default function MenuTopo({ history }) {
  const [openAgenda, setOpenAgenda] = useState(false);
  const [processos, setProcessos] = useState([]);
  const [processoSelecionado, setProcessoSelecionado] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { handleSubmit, register } = useForm();
  const [valueCpf, setValueCPF] = useState("");
  const {setListaProcessos} = useContext(ProcessoContext);
  const [nomeEmpreendimento, setNomeEmpreendimento] = useState(guService.getLocalState('NomeEmpreendimento'))
  const [utilizarDescricaoUnidade, setUltilizarDescricaoUnidade] = useState(false);
  const [nomeUnidade, setNomeUnidade] = useState(guService.getLocalState("unidade_numero") !== "" ? guService.getLocalState("unidade_numero") : guService.getLocalState("Unidade"))
  const [textoDescricaoUnidade, setTextoDescricaoUnidade] = useState('')
  const { setListaConfiguracaoes, aplicarEstilosMobile } = useContext(ConfiguracoesVisuaisContextContext)
  const NOME_COMPONENTE_BARRA_NAVEGACAO = 'barra de navegação'

  useEffect(() => {
    var r = document.querySelector(":root");
    r.style.setProperty(
      "--dommus-color-2",
      guService.getLocalState("cor_secundaria")
    );
    r.style.setProperty(
      "--dommus-background-color",
      guService.getLocalState("cor_fundo")
    );
    r.style.setProperty(
      "--dommus-overflow-width",
      guService.getLocalState("largura_overflow")
    );
    r.style.setProperty(
      "--dommus-overflow-height",
      guService.getLocalState("largura_overflow")
    );
    if( guService.getLocalState("logo_branca") == true){
      r.style.setProperty(
          "--dommus-cor-logo",
          'brightness(0) invert(1)'
      );
    }
    buscarProcessosCpf();
    buscarNomeEmpreendimento();
    buscarConfiguracoesVisuais();
  }, []);

  useEffect(() => {
    if(utilizarDescricaoUnidade){
      const novoNomeUnidade = guService.getLocalState("Unidade") !== "" ? guService.getLocalState("Unidade") : guService.getLocalState("unidade_numero")
      setNomeUnidade(novoNomeUnidade)
    }else{
      const novoNomeUnidade = guService.getLocalState("unidade_numero") !== "" ? guService.getLocalState("unidade_numero") : guService.getLocalState("Unidade")
      setNomeUnidade(novoNomeUnidade)
    }
  },[utilizarDescricaoUnidade])

  function buscarConfiguracoesVisuais() {
    configuracaoService.buscarConfiguracoesVisuais().then((response) => {
      setListaConfiguracaoes(response.data)
      const configuracao = response.data.find(configuracao => configuracao.chave === "utilizar_descricao_unidade_header" );            
      if(configuracao && configuracao?.valor){
          setUltilizarDescricaoUnidade(JSON.parse(configuracao?.valor));
      }
      const configuracaoTextoDescricaoUnidade = response.data.find(configuracao => configuracao.chave === 'texto_descricao_unidade_navbar')
      if(configuracaoTextoDescricaoUnidade?.valor) {
        setTextoDescricaoUnidade(configuracaoTextoDescricaoUnidade.valor)
      }
    });
  }

  function buscarNomeEmpreendimento() {
    const idEmpreendimento = guService.getLocalState('IdEmpreendimento')
    if(idEmpreendimento && nomeEmpreendimento) {
      return
    }
    empreendimentoService.buscarEmpreendimentoPorId(idEmpreendimento).then(response => {
      guService.setLocalState('NomeEmpreendimento', response?.data?.nome)
      setNomeEmpreendimento(response?.data?.nome)
    })      
  }

  function buscarProcessosCpf(cpfAlterado = false) {
    const cpf = guService.getLocalState("CPF")
    if(!cpf || cpf == 'null') {
      return
    }
    processoService
      .buscarProcessosCpf(cpf)
      .then((response) => {
        const dados = response.data;
        guService.setLocalState("usuarioTipo", dados[0]?.tipo_usuario);
        if (dados[0] != undefined) {
          setOpenModal(false);
          let processosList = [];
          dados.forEach((valor) => {
            processosList.push(valor);
          });
          if (cpfAlterado || !guService.getLocalState("Processo")) {
            buscarProcessosId(dados[0]["id"]);
            setProcessoSelecionado(dados[0]["id"]);
            guService.setLocalState("Processo", dados[0]["id"]);            
          } else {
            buscarProcessosId(guService.getLocalState("Processo"));
          }
          guService.setLocalState("url_logo_empresa", dados[0]["url_logo_empresa"] ?? "");
          guService.setLocalState("nomeProponente", dados[0]['proponente'] ?? "")
          setProcessos(processosList);
          setListaProcessos(processosList);
        } else {
          Swal.fire({
            titleText: "CPF Inválido.",
            text: "Não encontramos nenhum processo vinculado a este CPF.",
            icon: "error",
          });
          setOpenModal(true);
        }
      }).catch(error => {
        console.log(error)
        console.log(error?.response)
        if(error?.response?.data?.motivo) {
          erroSwal(error?.response?.data?.motivo).then(() => {
            window.location.href = `/login/${guService.getLocalState("slug")}`
            localStorage.clear()
            window.reload()
          })
        }
      })
  }

  function buscarProcessosId(id) {
    const processoAtual = guService.getLocalState("Processo");
    if(formatDommus.valoresVazios.includes(id)) {
      return
    }
    processoService.buscarDadosProcesso(id).then((response) => {
      const dados = response.data;
      if (dados.processo != undefined) {
        setOpenModal(false);
        guService.setLocalState("Processo", dados.processo.processo_id);
        guService.setLocalState("Bloco", dados.processo.unidade_bloco);
        guService.setLocalState("Unidade", dados.processo.unidade_descricao);
        guService.setLocalState("unidade_numero", dados?.processo?.unidade_unidade ?? "");
        guService.setLocalState("bloco_nome", dados?.processo?.bloco_nome ?? "");
        guService.setLocalState(
          "IdEmpreendimento",
          dados.processo.processo_empreendimento
        );
        guService.setLocalState(
          "NomeEmpreendimento",
          dados.processo.empreendimento_nome
        );
        if (id != processoAtual) {
          window.location.reload();
        }
      } else {
        Swal.fire({
          titleText: "CPF Inválido.",
          text: "Não encontramos nenhum processo vinculado a este CPF.",
          icon: "error",
        });
        setOpenModal(true);
      }
    });
  }
  function logout(e) {
    e.preventDefault();
    guService.logout();
    window.location.reload(false);
  }

  // function openLateral(){
  //   const lateral = !localStorage.getItem('dommusMenuMobile');
  //   console.log(lateral);
  //   //localStorage.setItem('dommusMenuMobile', !lateral);

  // }

  const submitFormCliente = (data) => {
    guService.setLocalState("CPF", data.cpf.match(/\d+/g));
    buscarProcessosCpf(true);
  };

  function splitaCor() {
    const cor = guService.getLocalState("cor_primaria");
    const corSplitada = cor?.split("_");
    var r = document.querySelector(":root");
    if (corSplitada?.length >= 2) {
      r.style.setProperty("--dommus-color", corSplitada[0].toString());
      return {
        background: `linear-gradient(to right, ${corSplitada[0]} , ${corSplitada[1]})`,
      };
    } else {
      r.style.setProperty("--dommus-color", corSplitada[0].toString());
      return { backgroundColor: `${corSplitada[0]}` };
    }
  }

  function escolheLogo() {
    const slug = guService.getLocalState("slug");
    const logoEmpresa = guService.getLocalState("url_logo_empresa");
    if(logoEmpresa){
      return logoEmpresa;
    }

    if (guService.getLocalState(`logo_cliente_${slug}`)) {
      return guService.getLocalState(`logo_cliente_${slug}`);
    } else {
      return guService.getLocalState(`logo_cliente`);
    }
  }

  return (
    <>
      <nav className="dommus-menu" style={splitaCor()}>
        <div className="logo">
          <Link 
            to="./" 
            onClick={() => {
              LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_BARRA_NAVEGACAO, null, { 
                componente: 'logo', 
                url: window.location.href
              })
            }}>
            <picture>
              <img src={escolheLogo()} alt="CRM - Dommus Sistemas" />
            </picture>
          </Link>{" "}
          <span className="textoPortal"> | Portal do {guService.getLocalState('usuarioTipo') == 'SC' && 'Síndico' || 'Cliente'}</span>
        </div>
        <div className="right-nav">
          <div className="info-nav">
            <div className="online">
              <h1 className="modulo-name flex-column d-md-flex align-items-center">
                {isDesktop || !aplicarEstilosMobile ? 
                  <div className="d-flex align-items-center">
                    <div className="empreendimentoTopo">
                      <span className="nome-empreendimento">{guService.getLocalState("NomeEmpreendimento")}{" "}</span>
                      {!formatDommus.valoresVazios.includes(guService.getLocalState("Processo")) && (
                        <div className="dadosEmpreendimento">
                          <Dropdown
                            onToggle={(isOpen, event) => event && LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_BARRA_NAVEGACAO, null, {
                                url: window.location.href,
                                componente: 'seleção de processo',
                                acao: isOpen && 'abriu' || 'fechou'
                              }) || {}
                            }
                          >
                            <Dropdown.Toggle className="perfil-dropdown perfil-dropdown-processo">
                              <span
                                style={{ alignSelf: "flex-end", marginRight: 10 }}
                              >
                                Contrato:
                              </span>
                              Nº{" "}
                              {guService.getLocalState("Processo")
                                ? guService
                                    .getLocalState("Processo")
                                    .padStart(5, "0") + " "
                                : ""}
                              {processos.length > 1 ? (
                                <i className="arrow down"></i>
                              ) : (
                                <></>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {processos.map((valor) => {
                                return (
                                  <Dropdown.Item
                                    key={valor.id}
                                    eventKey="4"
                                    onClick={() => {
                                      LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_BARRA_NAVEGACAO, null, {
                                        url: window.location.href,
                                        componente: 'processo',
                                        idProcesso: valor.id
                                      })
                                      buscarProcessosId(valor.id)
                                    }}
                                  >
                                    <div className="processoBox">
                                      <>
                                        <FontAwesomeIcon
                                          icon={faBuilding}
                                          color="#7882A4"
                                          style={{ marginRight: 5 }}
                                        />
                                        {utilizarDescricaoUnidade ? valor?.descricao_unidade ?? valor?.unidade ?? "" : valor?.unidade}
                                        {" - "}
                                        {valor.empreendimento_nome}
                                      </>
                                      <>
                                        <AiOutlineFieldNumber
                                          color="#8A39E1"
                                          style={{ marginRight: 5, marginLeft: 5 }}
                                          size={25}
                                        />
                                        {valor.id}
                                      </>
                                    </div>
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    {!formatDommus.valoresVazios.includes(guService.getLocalState("Processo")) && (<>
                      <span style={{ fontWeight: "200", fontSize: 40 }}>|</span>
                      <div className="blocoUnidadeContainer">
                        <div>{textoDescricaoUnidade || 'Unidade:'} {nomeUnidade}</div>
                        {guService.getLocalState("bloco_nome") != "" ? guService.getLocalState("bloco_nome") :  `Bloco: ${guService.getLocalState("Bloco")}`}
                      </div>
                    </>)}
                  </div>
                :<></>
                }
              </h1>
              <Dropdown>
                <Dropdown.Toggle className="perfil-dropdown">
                  <div
                    onClick={() =>LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_BARRA_NAVEGACAO, null, {
                        url: window.location.href,
                        componente: 'usuario'
                      })
                    }
                    className="mobile-user-section"
                  >
                    <div className="mobileUserBox">
                      <Icon icon={userCicle} style={{fontSize: "2rem"}}/>
                    </div>
                    <div className="userBox">
                      <DommusOnlineFlag />
                      <h1 className="user-name">
                        Olá, {guService.getLocalState("Nome")}
                      </h1>
                      <i className="arrow down arrow-mobile" />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {guService.getLocalState("guLogin") ? (
                    <>
                      <div className="alterarUsuarioBox">
                        <FontAwesomeIcon
                          icon={faPeopleArrows}
                          color="#573391"
                        />
                        <Dropdown.Item
                          eventKey="4"
                          onClick={() => setOpenModal(true)}
                        >
                          Alterar Cliente
                        </Dropdown.Item>
                      </div>
                      <Dropdown.Divider />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="alterarUsuarioBox">
                    <FontAwesomeIcon icon={faSignOutAlt} color="#EF6D6D" />
                    <Dropdown.Item
                      eventKey="4"
                      onClick={async () => {
                        try {
                          await LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_BARRA_NAVEGACAO, null, {
                            url: window.location.href,
                            componente: 'logout'
                          })
                          window.location.assign("/sair");
                          
                        } catch (error) {
                          window.location.assign("/sair");
                        }
                       
                      }}
                    >
                      Sair
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <picture
              className={"picture-topo"}
              onClick={() => {
                setOpenAgenda((statusAtual) => {
                  return !statusAtual;
                });
                LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_BARRA_NAVEGACAO, null, {
                  url: window.location.href,
                  componente: 'agenda',
                  acao: openAgenda ? 'fechou' : 'abriu'
                })
              }}
            >
              <Icon icon={calendarOutlineBadged} width="30" />
            </picture>            
            {/* Quando for implementar as funcionalidades ativar os icones */}
            {/* <picture className={"picture-topo"}>
              <img src={alarm} alt="Ícone de sino" />
            </picture> */}
            {/* <AiOutlineMenu className="barsMenuMobile" /> */}
          </div>
        </div>
      </nav>
      <ModalDommus
        open={openModal}
        size={"sm"}
        close={() => {
          setOpenModal(false);
          setValueCPF("");
        }}
        titulo={`Alterar Cliente`}
        content={
          <Form
            className={"formCadastro"}
            onSubmit={handleSubmit(submitFormCliente)}
          >
            <Container fluid>
              <Form.Group>
                <Form.Label>CPF/CNPJ:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o CPF ou CNPJ"
                  ref={register()}
                  name={"cpf"}
                  value={valueCpf}
                  //pattern="[0-9]{11}"
                  onChange={(e) => setValueCPF(mascaraCpfCnpj(e.target.value))}
                  //   onBlur={formatDommus.formataCPF()}
                />
              </Form.Group>
              <div
                className="d-flex justify-content-center"
                style={{ marginBottom: 10 }}
              >
                <Button variant={"success"} type={"submit"}>
                  <FontAwesomeIcon
                    icon={faSave}
                    style={{ marginRight: "15px" }}
                    color=""
                  />
                  Salvar
                </Button>
              </div>
            </Container>
          </Form>
        }
        className="modalAlterarCliente"
      />
      <Agenda
        openAgenda={openAgenda}
        closeAgenda={() => {
          setOpenAgenda(false);
        }}
      />
    </>
  );
}
